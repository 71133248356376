<template>
  <div>
    <Tabs value="1" @on-click="handleTabRemove">
      <TabPane label="平台更新" name="1"></TabPane>
      <TabPane label="微信更新" name="2"></TabPane>
      <TabPane label="支付宝更新" name="3"></TabPane>
    </Tabs>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Button icon="md-add" type="primary" @click="addUser">新增</Button>
        </div>
      </Col>
    </Row>
    <Table border :columns="worlkColumns" :data="workData">
      <template slot-scope="{ row }" slot="operation">
        <Button type="primary" size="small" style="margin-right: 5px" @click="show(row)">编辑</Button>
        <Button type="error" size="small" @click="remove(row)" style="margin-right: 5px">删除</Button>
      </template>
    </Table>
    <br/>
    <Page :total="storePrams.total"
          :current="storePrams.page"
          style="text-align:right"
          show-elevator
          show-total
          @on-change="pageChange"
          :page-size="storePrams.limit"/>
    <Modal
        :title="formTitle"
        v-model="showModel"
        width="600"
        :mask-closable="false"
        @on-ok="submit"
        class-name="vertical-center-modal">
      <Form :model="formItem" :label-width="120" style="width: 500px">
        <FormItem label="版本标题">
          <Input v-model="formItem.title" placeholder="请输入版本标题"></Input>
        </FormItem>
        <FormItem label="版本详情">
          <Input v-model="formItem.content" type="textarea" :autosize="{minRows: 3,maxRows: 5}"
                 placeholder="请输入版本详情"></Input>
        </FormItem>
        <FormItem label="版本号">
          <Input v-model="formItem.version" placeholder="请输入版本号"></Input>
        </FormItem>
        <FormItem label="更新日期">
          <DatePicker type="date" v-model="formItem.updated_at" placeholder="请选择更新日期" style="width: 100%"></DatePicker>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import {versionLs, versionDel, versionAdd, versionInfo, versionUp} from '../../api/operation'
import moment from "moment";
export default {
  name: "index",
  data(){
    return{
      storePrams: {
        limit: 10,
        page: 1,
        type: '1',
        total:0,
      },
      worlkColumns: [
        {
          title: '#',
          key: 'id'
        },
        {
          title: '标题',
          key: 'title'
        },
        {
          title: '版本号',
          key: 'version'
        },
        {
          title: '更新日期',
          key: 'updated_at'
        },
        {
          title: '操作',
          key: 'action',
          width: 200,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ],
      workData:[],
      formTitle:'',
      showModel:false,
      formItem:{
        title:'',
        content:'',
        updated_at:'',
        version:'',
      }
    }
  },
  created() {
    this.getList()
  },
  methods:{
    handleTabRemove(e){
      this.storePrams.type = e
      this.getList()
    },
    getList(){
      versionLs(this.storePrams).then(res=>{
        this.workData = res.data.data || []
        this.storePrams.total = res.data.total
      })
    },
    show(row){
      this.formTitle = '编辑版本'
      versionInfo({id:row.id}).then(res=>{
        this.formItem = res.data
        this.showModel = true
      })

    },
    remove(row){
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此版本信息，删除后无法恢复！！！',
        onOk: () => {
          versionDel({id: row.id}).then(res => {
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
    addUser(){
      this.formTitle = '新增版本'
      this.reset()
      this.showModel = true
    },
    submit(){
      if(this.formItem.id){
        versionUp(this.formItem).then(res => {
          this.$Message.success(res.msg)
          this.getList()
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      }else {
        this.formItem.updated_at = moment(this.formItem.updated_at).format('YYYY-MM-DD')
        this.formItem.type = this.storePrams.type
        versionAdd(this.formItem).then(res => {
          this.$Message.success(res.msg)
          this.getList()
        }).catch(res => {
          this.$Message.error(res.msg)
        })
      }
    },
    reset(){
      this.formItem={
        title: '',
        content: '',
        updated_at: '',
        version: '',
        id:undefined
      }
    }
  }
}
</script>

<style scoped>

</style>

